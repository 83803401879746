import React from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Link, SvgIcon } from '@material-ui/core';


const HeaderItem = (props) => {
  return (
    <Link href={props.link} onClick={() => props.logout ? props.logout() : undefined} className={`header-item ${props.active ? 'active' : ''}`}>
      <div className={`icon ${props.icon}-icon`}></div>
      {props.title && <span role='button' className='nav-item' >{props.title}</span>}
    </Link>
  );
}

export default HeaderItem;
