export const handleFilterChangeUtil = (
  updatedFilter,
  selectedFilters,
  setSelectedFilters,
) => {
  const existingFilters = { ...selectedFilters };
  existingFilters[updatedFilter.name] = updatedFilter.value;
  localStorage.setItem('filters', JSON.stringify(existingFilters));
  setSelectedFilters(existingFilters);
};


export const handleFilterClearUtil = (
  filterName,
  filterValue,
  selectedFilters,
  setSelectedFilters,
) => {
  const existingFilters = { ...selectedFilters };
  const existingFilter = existingFilters[filterName];
  if (!existingFilter) {
    return;
  }
  const newFilters = filterValue ? existingFilter.filter(
    (filter) => filter.value !== filterValue,
  ) : [];
  existingFilters[filterName] = newFilters;
  localStorage.setItem('filters', JSON.stringify(existingFilters));
  setSelectedFilters(existingFilters);
};


export const getBarometerFill = (score) => {
  if (score > 7) {
    return '#7CB45D'
  } else if (score > 5) {
    return '#F2B111'
  } else {
    return '#DA4832'
  }
}