import React from 'react';
import {Grid, Box, Typography, Button, TextField, InputLabel, Select, MenuItem, Modal, Fade, Backdrop} from '@material-ui/core';
import MainContentWrapper from '../../components/wrappers/MainContent/MainContent';
import BlockWrapper from '../../components/wrappers/Block/Block';
import Heading from '../../components/heading/Heading';
import './Invite.sass'

export const InviteJSX = ({
  handleInviteSubmission,
  handleSelectChange,
  selectValue,
  emailValue,
  handleEmailChange,
  emailInvalid,
  successModalOpen,
  handleModalClose
}) => {
  return (
    <MainContentWrapper>
      <Box mt={3.157} mb={1}>
        <Heading>Invite a Patient</Heading>
      </Box>
      <BlockWrapper>
        <form onSubmit={handleInviteSubmission}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Box mx={1}>
                <InputLabel className='form-label primary-font'>Status</InputLabel>
                <Select
                  name='status'
                  className={'status-select'}
                  onChange={handleSelectChange}
                  value={selectValue}
                >
                  <MenuItem value={'accepted'}>Accepted</MenuItem>
                  <MenuItem value={'declined'}>Declined</MenuItem>
                </Select>
            </Box>
            </Grid>
            {
              selectValue === 'accepted' &&
                <Grid item xs={12} md={4}>
                  <Box mx={1}>
                    <InputLabel className='form-label primary-font'>Email Address</InputLabel>
                  <TextField value={emailValue} onChange={handleEmailChange} variant={'outlined'} size={'small'} className='full-width input-theme'/>
                  </Box>
                </Grid>
            }
        </Grid>
        <Grid container>
          <Grid item xs={12} md={3}>
              <Box mx={1} my={3.157}>
                {emailInvalid && <Typography variant={'subtitle1'} className={'error-text'}>Please enter a valid email address</Typography>}
                <Button variant="contained" align="center" className='btn-theme' type='submit'><strong>Submit</strong></Button>
            </Box>
          </Grid>
        </Grid>
        </form>
      </BlockWrapper>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={'modal'}
        open={successModalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 50,
        }}
      >
        <Fade in={successModalOpen}>
          <div className={'modal-paper'}>
            <Typography variant={'h5'} className={'success-message'}>Invite submitted successfully</Typography>
              <Button onClick={handleModalClose} variant="contained" align="center" className='btn-theme'><strong>Close</strong></Button>
          </div>
        </Fade>
      </Modal>

    </MainContentWrapper>
  )
}

export default InviteJSX
