import React from 'react';
import {Link} from 'react-router-dom';
import {Typography, Grid, Box, Button, TextField, InputLabel, CircularProgress} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export const VerifyJsx = (props) => {
return (
<div className='verify'>
<Grid container spacing={0}>
  <Grid item xs={12} className='right-col'>
  <form className={`form-container ${props.fadedLeft ? 'faded-left' : 'not-faded'} ${props.fadedRight ? 'faded-right' : 'not-faded'}`} onSubmit={(e) => props.handleSubmit(e)}>
    <div className='logo-round'></div>
    <Box m={2.369}>
    <Typography variant="h4" className="form-title primary-font">
    Confirmation
    </Typography>
    </Box>
    <Box m={2.369}>
    <Typography variant="subtitle1"  className="primary-font form-title">
    A verification code has been sent to your email
    </Typography>
    </Box>

      <Box m={1} className='form-group'>
        <InputLabel className='form-label primary-font'>Verification Code</InputLabel>
        <TextField variant="outlined" size="small" id="verificationCode" className='full-width input-theme' type='text' onKeyUp={(e) => props.setFormValues(e)}/>
      </Box>
      <Box m={2.369} display="flex" flexDirection="center">
      {
        !props.isLoading && !props.registrationSuccess && <Button variant="contained" align="center" className='btn-theme' type='submit'>Submit</Button>
      }
      {
        props.isLoading && !props.registrationSuccess && <CircularProgress className="centered" />
      }
      {
        props.registrationSuccess &&
        <Box display="flex" justifyContent="center">
        <Box mr={1.777} mt={0.75}><CheckCircleIcon className={'green'}/></Box>
        <Typography variant="subtitle2">Thank you for registering, an administrator will be in touch shortly!</Typography>
        </Box>
      }
      </Box>
      <Box display="flex" justifyContent="center">
        {
        !props.registrationSuccess && <Button align="center" className="resend-btn" onClick={() => props.resendVerification()}>Resend Verification Code</Button>
        }
      </Box>
      {<InputLabel className='error-label form-link'>{props.verificationError}</InputLabel>}
      <Box mx={1.777} mt={0.75} display="flex" justifyContent="center">
      <Typography className="small-font" type='subtitle2'>{props.verificationRefresh}</Typography>
      </Box>
      <Box mb={2.369}>
      <div className='nhs-logo'></div>
      </Box>
    </form>
    </Grid>
</Grid>
</div>
)
}

export default VerifyJsx;
