import React, { useEffect, useState } from 'react';
import {AppBar} from '@material-ui/core';
import HeaderItem from './HeaderItem';
import { profile } from '../../api';


export const HeaderJsx = (props) => {
  const [isAdmin, setIsAdmin] = useState(undefined);
  // TODO: Move this profile data into context store
  useEffect(() => {
    getPermissions();
  }, []);
  const getPermissions = async () => {
    const profileData = await profile();
    setIsAdmin(profileData.profile.role === 'nhs_admin')
  }
return (
  <AppBar position="static" className='app-header'>
    {/* <Toolbar variant="dense"> */}
      <span className='logo'/>
      <div className={'centre-items'}>
      {isAdmin !== undefined &&
        <>
          <HeaderItem title={'Dashboard'} link={'/dashboard'} icon={'dashboard'} active={ props.active === 'dashboard' } />
          <HeaderItem title={'Stats'} link={'/stats'} icon={'stats'} active={ props.active === 'stats' } />
          <HeaderItem title={'Safe-7'} link={'/safe7'} icon={'safe7'} active={ props.active === 'safe7' } />
          <HeaderItem title={'Invite'} link={'/invite'} icon={'invite'} active={ props.active === 'invite' } />
          <HeaderItem title={'Export'} link={'/export'} icon={'export'} active={ props.active === 'export' } />
        {isAdmin && <HeaderItem title={'Admin'} link={'https://api.dashboard.safe7.co.uk/sitepanel'} icon={'admin'} active={props.active === 'admin'} />}
        </>
      }
    </div>
    <div  className={'right-items'}>
    <HeaderItem title={'Log Out'} logout={props.logout} icon={'logout'} />
    <HeaderItem icon='profile' link={'/profile'} />
    </div>
    {/* </Toolbar> */}
  </AppBar>
)
}

export default HeaderJsx;
