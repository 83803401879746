import React from 'react';
import {Link} from 'react-router-dom';
import {Typography, Grid, Box, Button, TextField, InputLabel, CircularProgress} from '@material-ui/core';

export const ResetPasswordJsx = (props) => {
return (
<div className='login'>
<Grid container spacing={0}>
  <Grid item xs={12} className='right-col'>
  <form className='form-container' onSubmit={(e) => props.handleSubmit(e)}>
    <div className='logo-round'></div>
    <Box m={2.369}>
    <Typography variant="h4" className="form-title primary-font">
    Reset your password
    </Typography>
    </Box>
    <Box m={1} className='form-group'>
      <InputLabel className='form-label'>Enter your new password</InputLabel>
      <TextField disabled={props.confirmSuccess} variant="outlined" size="small" id="resetPassword" type="password" className='full-width' onKeyUp={(e) => props.setFormValues(e)}/>
      {<InputLabel className='error-label form-link'>{props.passwordErr}</InputLabel>}
    </Box>
    <Box m={1} className='form-group'>
      <InputLabel className='form-label'>Confirm your new password</InputLabel>
      <TextField disabled={props.confirmSuccess} variant="outlined" size="small" id="confirmPassword" type="password" className='full-width'onKeyUp={(e) => props.setFormValues(e)}/>
      {<InputLabel className='error-label form-link'>{props.confirmErr}</InputLabel>}
    </Box>
      <Box m={2.369} display="flex" flexDirection="center">
      {
      !props.isLoading && <Button variant="contained" align="center" className='btn-theme' type="submit">Reset Password</Button>
      }
      {
      props.isLoading && <CircularProgress className="centered" />
      }
      </Box>
      {props.confirmSuccess && <InputLabel className='success-label form-link'>{props.confirmSuccess}</InputLabel>}
      <Box m={2.369} display="flex" flexDirection="center">
      <Link className='form-link secondary-font full-width' to="/login">Go to Login</Link>
      </Box>
    </form>
    </Grid>
</Grid>
</div>
)
}

export default ResetPasswordJsx;
