import React from 'react';
import {Grid, Box, Typography, Button, TextField, InputLabel, Divider, Backdrop, Modal, Fade } from '@material-ui/core';
import MainContentWrapper from '../../components/wrappers/MainContent/MainContent';
import BlockWrapper from '../../components/wrappers/Block/Block';
import Heading from '../../components/heading/Heading';
import './Profile.sass';

const ProfileJsx = ({
  profileData,
  handlePasswordSubmit,
  errorMessage,
  passwordFieldRef,
  repeatPasswordFieldRef,
  passwordTooWeak,
  successModalOpen,
  handleModalClose
}) => {
  return (
    <MainContentWrapper>
      <Box mt={3.157} mb={1}>
        <Heading>My Profile</Heading>
      </Box>
      <BlockWrapper>
        <Box mx={1}>
        <Grid container>
          <Grid xs={12} md={2}>
            <Typography variant={'subtitle1'}>Name</Typography>
              <Typography variant={'h6'}><strong>{profileData.name || `Not Set`}</strong></Typography>
          </Grid>
          <Grid xs={12} md={2}>
            <Typography variant={'subtitle1'}>Surname</Typography>
            <Typography variant={'h6'}><strong>{profileData.last_name || `Not Set`}</strong></Typography>
          </Grid>
          <Grid xs={12} md={4}>
            <Typography variant={'subtitle1'}>Email Address</Typography>
              <Typography variant={'h6'}><strong>{profileData.email}</strong></Typography>
          </Grid>
          <Grid xs={12} md={4}>
            <Typography variant={'subtitle1'}>Role</Typography>
            <Typography variant={'h6'}><strong>{profileData.job_title || `Not Set`}</strong></Typography>
          </Grid>
        </Grid>
        </Box>
        <Box my={3.157}>
          <Divider />
        </Box>
        <form onSubmit={handlePasswordSubmit}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Box mx={1}>
            <InputLabel className='form-label primary-font'>New Password</InputLabel>
                <TextField inputRef={passwordFieldRef} variant={'outlined'} size={'small'} type={'password'} className='full-width input-theme'/>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box mx={1}>
              <InputLabel className='form-label primary-font'>Re-enter new password</InputLabel>
                <TextField inputRef={repeatPasswordFieldRef} variant={'outlined'} size={'small'} type={'password'} className='full-width input-theme'/>
            </Box>
          </Grid>
          </Grid>
          {(errorMessage || passwordTooWeak) &&
            <Grid container>
              <Grid item xs={12} md={3}>
                <Box mx={1} my={1}>
                <Typography variant={'subtitle1'} className={'error-text'}>{errorMessage}</Typography>
                {passwordTooWeak &&
                  <>
                  <Typography variant={'subtitle1'} className={'password-rule'}>Please ensure your password has:</Typography>
                  <Typography variant={'subtitle1'} className={'password-rule'}>- At least 1 special character</Typography>
                  <Typography variant={'subtitle1'} className={'password-rule'}>- At least 1 upper case character</Typography>
                  <Typography variant={'subtitle1'} className={'password-rule'}>- At least 1 lowercase character</Typography>
                  <Typography variant={'subtitle1'} className={'password-rule'}>- At least 1 number</Typography>
                  <Typography variant={'subtitle1'} className={'password-rule'}>- Minimum length of 12 characters</Typography>

                  </>
                  
                }
                </Box>
              </Grid>
            </Grid>
          }
        <Grid container>
          <Grid item xs={12} md={3}>
            <Box mx={1} my={3.157}>
            <Button variant="contained" align="center" className='btn-theme' type='submit'><strong>Update</strong></Button>
            </Box>
          </Grid>
        </Grid>
        </form>
      </BlockWrapper>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={'modal'}
        open={successModalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 50,
        }}
      >
        <Fade in={successModalOpen}>
          <div className={'modal-paper'}>
            <Typography variant={'h5'} className={'success-message'}>Password changed successfully</Typography>
              <Button onClick={handleModalClose} variant="contained" align="center" className='btn-theme'><strong>Close</strong></Button>
          </div>
        </Fade>
      </Modal>
    </MainContentWrapper>
  )
}

export default ProfileJsx
