import React from 'react';
import { Box, Typography, Grid, Link } from '@material-ui/core';
import BlockWrapper from '../../wrappers/Block/Block';
import { BarometerBlock } from './BarometerBlock';

const BarometerBlockContainer = ({
  title,
  link,
  fullWidthAtTop,
  barometers,
  linkToQuestions
}) => {
  return (
    <BlockWrapper>
      <Grid container>
        <Grid item xs={6}>
          <Box my={1.777}>
            <Typography variant={'h5'}>{title}</Typography>
          </Box>
        </Grid>
        {link && (
          <Grid item xs={6}>
            <Link href={'/safe7'}>
              <Typography variant={'subtitle1'} align={'right'}>
                View more
              </Typography>
            </Link>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={fullWidthAtTop ? 12 : 6}>
          <BarometerBlock
            isFullWidth={fullWidthAtTop}
            score={barometers.safe_care}
            copy={'Overall, how safe did you feel under our care?'}
            link={linkToQuestions ? '/safe7/safe-care' : undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <BarometerBlock
            score={barometers.safe_speed}
            copy={'How satisfied were you with the speed of the service?'}
            link={linkToQuestions ? '/safe7/safe-speed' : undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <BarometerBlock
            copy={'How kind were the team that cared for you?'}
            score={barometers.safe_kind}
            link={linkToQuestions ? '/safe7/safe-kind' : undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <BarometerBlock
            copy={
              'How easily were you able to access the team if you needed them?'
            }
            score={barometers.safe_access}
            link={linkToQuestions ? '/safe7/safe-access' : undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <BarometerBlock
            copy={
              'How clear was the information you were provided with by our team?'
            }
            score={barometers.safe_clarity}
            link={linkToQuestions ? '/safe7/safe-clarity' : undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <BarometerBlock
            copy={
              'How would you rate your level of trust of confidence in the team that cared for you?'
            }
            score={barometers.safe_trust}
            link={linkToQuestions ? '/safe7/safe-trust' : undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <BarometerBlock
            copy={
              'How would you rate how well we listened to you and your worries/concerns?'
            }
            score={barometers.safe_listened}
            link={linkToQuestions ? '/safe7/safe-listened' : undefined}
          />
        </Grid>
      </Grid>
    </BlockWrapper>
  );
};

export default BarometerBlockContainer;