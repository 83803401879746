import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import './Verify.sass';
import VerifyJsx from './Verify.jsx';
import axios from 'axios';

function Verify() {

  const [key, setKey] = useState('');
  const [token, setToken] = useState('');
  const [authMode, setAuthMode] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [verificationError, setVerificationError] = useState('');
  const [verificationRefresh, setVerificationRefresh] = useState('')
  const [registrationSuccess, setRegistrationSuccess] = useState(false)
  const [fadedLeft, setFadedLeft] = useState(true)
  const [fadedRight, setFadedRight] = useState(false)
  const history = useHistory();

  useEffect(() => {
    let url_key = window.location.href.substring(
      window.location.href.lastIndexOf('/') + 1,
      window.location.href.length
    )
    let url_token = window.location.href.substring(window.location.href.lastIndexOf('-') + 1)
    setAuthMode(window.location.href.substring(window.location.href.lastIndexOf('?') + 1))
    setKey(url_key)

    if (url_token.length === 6) {
      if(url_key.substring(url_key.lastIndexOf('-') + 1, url_key.length).length === 6) {
        url_key = url_key.substring(0, url_key.lastIndexOf('-'))
        setKey(url_key)
      }
      let data = {
        key: url_key,
        token: parseInt(url_token)
      }
      setToken(url_token)
      handleLogin(data)
      }
    setFadedLeft(true);
    setTimeout(function () {
      setFadedLeft(false)
    }, 100);
  }, [])

  const setFormValues = (e) => {
    if (e.target.id === 'verificationCode') {
      setToken(parseInt(e.target.value))
    }
  }

  const handleSubmit = (e) => {
      e.preventDefault();
      let data = {
        key: key,
        token: token
      }
      setIsLoading(true)
      handleLogin(data)
  }

  const handleLogin = (data) => {
    console.log(data)
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/token/verify/2fa`, data)
      .then(res => {
        console.log(res);
        let timestamp = Date.now();
        localStorage.setItem('jwt', res.data.token);
        localStorage.setItem('timestamp', timestamp);
        localStorage.setItem('2FAkey', key);
        setIsLoading(false);
        setFadedRight(true)
        setTimeout(function () {
          history.push(`/dashboard`);
        }, 500);
      }).catch(err => {
        setVerificationError(err.response.data.error_message);
        setIsLoading(false);
      })
  }

  const resendVerification = () => {
    let data = {
      key: key
    }
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/token/refresh/2fa`, data)
      .then(res => {
        setVerificationRefresh('A new verification code has been sent to your inbox');
      })
      .catch(err => {
        setVerificationError(err.response.data.error_message);
      })
  }
  return(
    <div>
      <VerifyJsx
      handleSubmit={handleSubmit}
      setFormValues={setFormValues}
      isLoading={isLoading}
      verificationError={verificationError}
      verificationRefresh={verificationRefresh}
      resendVerification={resendVerification}
      registrationSuccess={registrationSuccess}
      fadedLeft={fadedLeft}
      fadedRight={fadedRight}
      />
    </div>
  )
}
export default Verify;
