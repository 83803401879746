import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import BlockWrapper from '../wrappers/Block/Block';
import { PieChartBlock } from '../charts/PieChartBlock';
import { LineChartBlock } from '../charts/LineChartBlock';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { pluralize } from '../../util/text-utils';

const EngagementScoreBlock = ({
  overallEngagementScore,
  lineChartData,
  completedBy,
  surveyInvitations,
  minMaxScores,
  averageScore,
}) => (
  <BlockWrapper>
    <Typography variant={'h5'}>Overall Engagement Score</Typography>
    <Grid container>
      <Grid item xs={12} md={4}>
        <Box mr={1} my={3.157} className={'theme-box'}>
          <PieChartBlock
            legend={true}
            pieData={overallEngagementScore}
            height={140}
            pieInnerRadius={30}
            pieOuterRadius={60}
          />
          {surveyInvitations && (
            <Box my={1.777}>
              <Typography variant={'subtitle1'}>
                <strong>
                  <FiberManualRecordIcon
                    fontSize={'small'}
                    style={{ color: '#7CB45D' }}
                    className={'responder-icon'}
                  />{' '}
                  Responder {surveyInvitations.responder.percentage}% ({surveyInvitations.responder.count})
                </strong>
              </Typography>
              <Typography variant={'subtitle1'}>
                <strong>
                  <FiberManualRecordIcon
                    fontSize={'small'}
                    style={{ color: '#4B6195' }}
                    className={'non-responder-icon'}
                  />{' '}
                  Non-Responder {surveyInvitations.non_responder.percentage}% ({surveyInvitations.non_responder.count})
                </strong>
              </Typography>
              <Typography variant={'subtitle1'}>
                <strong>
                  <FiberManualRecordIcon
                    fontSize={'small'}
                    style={{ color: '#FFEB3B' }}
                    className={'declined-icon'}
                  />{' '}
                  Declined {surveyInvitations.declined.percentage}% ({surveyInvitations.declined.count})
                </strong>
              </Typography>
            </Box>
          )}
          <Box>
            <Typography variant={'h4'} align={'center'}>
              <span style={{ fontSize: '1.5em' }}>
                <strong>{averageScore ? averageScore : `--`}</strong>
              </span>
              /10
            </Typography>
            <Typography variant={'h6'} align={'center'}>
              Average "Safe-7" Care Barometers
            </Typography>
          </Box>
          {minMaxScores && (
            <Box>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant={'subtitle1'}>Min Score</Typography>
                  <Typography variant={'subtitle2'}>
                    {minMaxScores.min_score}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={'subtitle1'}>Max Score</Typography>
                  <Typography variant={'subtitle2'}>
                    {minMaxScores.max_score}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box my={1.777} mx={1.777}>
            {completedBy && completedBy.total && (
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant={'subtitle1'}>
                    <strong>Family / Carers</strong>
                  </Typography>
                  <Typography variant={'subtitle2'}>
                    {`${completedBy.other} ${pluralize(
                      'User',
                      'Users',
                      completedBy.other,
                    )}`}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={'subtitle1'}>
                    <strong>Patients</strong>
                  </Typography>
                  <Typography variant={'subtitle2'}>
                    {`${completedBy.patient} ${pluralize(
                      'User',
                      'Users',
                      completedBy.patient,
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={8}>
        <Box mx={1} my={3.157} className={'theme-box'}>
          <Box my={6.94}>
            <LineChartBlock height={316} barData={lineChartData} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  </BlockWrapper>
);

export default EngagementScoreBlock;;
