 
 export const getBase64 = (file, customFileType) => {
  // Convert file to Blob if not already
  let blobFile = file;
  if (!(blobFile instanceof Blob)) {
    blobFile = new Blob([file], { type: customFileType ? customFileType : file.type });
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blobFile);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};