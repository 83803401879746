import React, { useState, useRef } from 'react';
import { Button } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import formatISO from 'date-fns/formatISO';
import './Heading.sass';
import { ReactComponent as ExportIcon } from '../../assets/images/icon-export.svg'

const Heading = ({ children, showExport, handleExport, safe7Question }) => {
  const [exportData, setExportData] = useState();
  const [exportName, setExportName] = useState();
  const downloadCSVButton = useRef();
  const download = async () => {
      try {
        const data = await handleExport()
        setExportName(formatISO(new Date()))
        setExportData(data);
        downloadCSVButton.current.link.click();
      } catch (error) {
        console.error(error)
      }
  }
  return <div className='heading'>
    <div className={'headings-container'}>
      {safe7Question && <span className={'safe7-question-heading'}>Safe-7 Question</span>}
      {children}
    </div>

    {
      showExport &&
      <>
        <Button
          onClick={download}
          className='export-button'
          startIcon={<ExportIcon height={18} />}>
          Export
        </Button>
        {exportData && <CSVLink
          data={exportData}
          filename={`safe7-export-${exportName}.csv`}
          className="csv-hidden-link"
          ref={downloadCSVButton}
          target="_blank"
        />}

      </>
    }
  </div>
}

export default Heading;