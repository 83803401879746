import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip,  } from 'recharts';


export const BarChartBlock = ({isVertical, barData, height, YAxisMaxDomain}) => {

  const data01 = [
    {
      "name": "Responder",
      "value": 114
    },
    {
      "name": "Non-Responder",
      "value": 52,
      "fill": "#004dff"
    },
    {
      "name": "Declined",
      "value": 34,
      "fill": "#ffdf00"
    }
  ];

  const XAxisKey = isVertical ? 'value' : 'name';
  const XAxisType = isVertical ? 'number' : 'category';
  const YAxisKey = isVertical ? 'name' : 'value';
  const YAxisType = isVertical ? 'category' : 'number';

  return (
    <ResponsiveContainer width={'95%'} height={height ? height : 238}>
      <BarChart
        margin={{  left: isVertical ? 20 : 0 }}
        layout={isVertical ? 'vertical' : 'horizontal'}
        data={barData ? barData : data01}
      >
          <XAxis bar domain={[0, YAxisMaxDomain || 'dataMax']} tickLine={isVertical ? true : false} dataKey={XAxisKey} type={XAxisType} />
        <YAxis
          dataKey={YAxisKey}
          type={YAxisType}
          domain={[0, YAxisMaxDomain || 'dataMax']}
          width={isVertical ? 120 : 40}
          tickLine={isVertical ? false : true} 
        />
        <CartesianGrid  vertical={isVertical ? true : false} horizontal={!isVertical ? true : false}  />
        <Tooltip position={"outside"} />
        <Bar dataKey='value' nameKey='name' fill='#7CB45D80' />
      </BarChart>
    </ResponsiveContainer>
  );
}
