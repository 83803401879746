import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box, Typography } from '@material-ui/core';


const DataTableJsx = ({data}) => {

    const {title, items} = data

    return (
        <Box>
            <Header nameTitle={title.name} valueTitle={title.value}  />
            {!!items?.length > 0 && items.map(item => <Row label={item.label} count={item.count} />)}
        </Box>
    )
}

DataTableJsx.propTypes = {}

export default DataTableJsx

const Header = ({nameTitle, valueTitle}) => (
    <Grid container className={'theme-box-bg other-list-item'}>
        <Grid item xs={6}><Typography>{nameTitle}</Typography></Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={1}><Typography>{valueTitle}</Typography></Grid>
    </Grid>
)


const Row = ({label, count}) => (
    <Grid container className={'other-list-item'}>
        <Grid item xs={6}><Typography>{label}</Typography></Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={1}><Typography>{count}</Typography></Grid>
    </Grid>
)

