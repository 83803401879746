import React from 'react';
import { PieChart, Pie, ResponsiveContainer, Tooltip } from 'recharts';

export const PieChartBlock = (props) => {

  const data01 = [
    {
      "name": "Responder",
      "value": 1,
      "fill":"#ddd"

    },
    {
      "name": "Non-Responder",
      "value": 1,
      "fill": "#ccc"
    },
    {
      "name": "Declined",
      "value": 1,
      "fill": "#bbb"
    }
  ];


  return(
    <ResponsiveContainer width={"95%"} height={props.height ? props.height : 238}>
      <PieChart>
        <Pie
          data={props.pieData ? props.pieData : data01}
          startAngle={90}
          endAngle={450}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={props.pieInnerRadius ? props.pieInnerRadius : 40}
          outerRadius={props.pieOuterRadius ? props.pieOuterRadius : 80}
          fill="#82ca9d"
        />
      </PieChart>
    </ResponsiveContainer>
  )
}
