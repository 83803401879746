export const capitalize = (word) => {
  return (word && word[0].toUpperCase() + word.slice(1)) || '';
};

export const normaliseDBString = (string) => {
  const capitalized = string.split('_').map(s => capitalize(s)).join(' ')
  return capitalized;
}

export const pluralize = (singularString, pluralString, number) => {
  if (number === 1) {
    return singularString;
  }
  return pluralString;
}