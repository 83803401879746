import React from 'react';
import {Link} from 'react-router-dom';
import {Typography, Grid, Box, Button, TextField, InputLabel, CircularProgress} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


export const ForgotPasswordJsx = (props) => {
return (
<div className='login'>
  <Grid container spacing={0}>
    <Grid item xs={12} className='right-col'>
    <form className='form-container' onSubmit={(e) => props.handleSubmit(e)}>
      <div className='logo-round'></div>
      <Box m={2.369}>
      <Typography variant="h4" className="form-title primary-font">
      Forgotten your password?
      </Typography>
      </Box>
      <Box m={1} className='form-group'>
        <InputLabel className='form-label primary-font'>Enter your email</InputLabel>
        <TextField variant="outlined" size="small" id="requestEmail" className='full-width' type='text' onKeyUp={(e) => props.setFormValues(e)}/>
        {<InputLabel className='error-label form-link'>{props.authErr}</InputLabel>}
      </Box>
        <Box m={2.369} display="flex" flexDirection="center">
        {
        !props.isLoading && !props.authSuccess && <Button variant="contained" align="center" className='btn-theme' type="submit">Request a New Password</Button>
        }
        {
        props.isLoading && !props.authSuccess && <CircularProgress className="centered" />
        }
        {
          props.authSuccess &&
          <Box display="flex" justifyContent="center">
          <Box mr={1.777} mt={0.75}><CheckCircleIcon className={'green'}/></Box>
          <Typography variant="subtitle2">A link to reset your password has been sent to your email</Typography>
          </Box>
        }
        </Box>
        <Box m={2.369} display="flex" flexDirection="center">
        <Link className='form-link secondary-font turquoise-font' to="/login">Go back</Link>
        </Box>
      </form>
      </Grid>
  </Grid>
</div>
)
}

export default ForgotPasswordJsx;
