import React, { useMemo, useState } from 'react';
import ExportJSX from './Export.jsx';
import { serviceExport } from '../../api';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { getBase64 } from '../../util/base64-util.js';


const Export = () => {
  const [errors, setErrors] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [date, setDate] = useState('30');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const filter = useMemo(() => {
    if (!date) return
    const isCustomDate = date === 'custom'
    if (isCustomDate) {
      if (!fromDate || !toDate) return null
    }
    setErrors('')
    let toArr = toDate.split('-')
    let fromArr = fromDate.split('-')
    return {
      serviceType: selectedFilters,
      date,
      toDate: isCustomDate ? toDate : '',
      fromDate: isCustomDate ? differenceInCalendarDays(new Date(toArr[0], toArr[1], toArr[2]), new Date(fromArr[0], fromArr[1], fromArr[2])) : ''
    }
  }, [selectedFilters, date, fromDate, toDate])

  const handleExportSubmission = async (event) => {
    if (!filter) {
      setErrors('You must select a time frame, or a to and from date')
      return {}
    }
    const res = await serviceExport(filter, true);
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = `word-cloud-report.xlsx`;
    const b64 = await getBase64(res, 'application/vnd.ms-excel')
    link.href = b64
    link.click()
  }


  const handleDateSelectChange = (event) => {
    setDate(event.target.value);
  }

  const handleCustomFromDate = (from) => {
    setFromDate(from.target.value)
  }

  const handleCustomToDate = (to) => {
    setToDate(to.target.value)
  }

  const handleFilterChange = (v, k) => {
    setSelectedFilters((prev) => (v))
  }

  const handleFilterClear = (v) => {
    setSelectedFilters((prev) => {
      const prevFilts = [...prev]
      const newFilts = prevFilts.filter(item => item.value !== v)
      return newFilts
    })
  }

  const props = {
    handleCustomFromDate,
    handleCustomToDate,
    date,
    handleDateSelectChange,
    handleExportSubmission,
    selectedFilters,
    handleFilterChange,
    handleFilterClear,
    errors
  }
  return (
    <ExportJSX
      {...props}
    />
  );
}

export default Export
