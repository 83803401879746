import React, { useState, useEffect } from 'react';
import Safe7jsx from './Safe7.jsx';
import Loader from '../Loader/loader';
import { safe7 } from '../../api';
import {
  handleFilterChangeUtil,
  handleFilterClearUtil,
} from '../../util/filter-utils';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';


const Safe7 = () => {
  const [serverData, setServerData] = useState({
    barometers: {},
    completed_by: {},
    daily_average: {},
    safe_care_scores: {},
    survery_completions_breakdown: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [filtersLoading, setFiltersLoading] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState({
    date: 30,
    completed_by: 'all',
    age: [],
  });
  const [customDateActive, setCustomDateActive] = useState(false)
  const [customFromDate, setCustomFromDate] = useState()
  const [customToDate, setCustomToDate] = useState()


  useEffect(() => {
    if(selectedFilters.date !== 'custom') {
      getData(selectedFilters);
       setCustomDateActive(false)
    } else {
      setCustomDateActive(true)
      if(customToDate && customFromDate) {
        setFiltersLoading(true)
        getData(selectedFilters);
      } else {
        setFiltersLoading(false)
      }
    }
  }, [selectedFilters]);

  // useEffect(() => {
  //   getExistingFilters();
  // }, []);

  const getExistingFilters = async () => {
    const existingFilters = await localStorage.getItem('filters');
    setSelectedFilters(JSON.parse(existingFilters));
  };

  const handleFilterChange = (updatedFilter) => {
    setFiltersLoading(true)
    handleFilterChangeUtil(
      updatedFilter,
      selectedFilters,
      setSelectedFilters,
    );
    if(updatedFilter == '1' || updatedFilter == '7' || updatedFilter == '30' || updatedFilter == '90' ) {
      setCustomDateActive(false)
    }
  };

  const handleCustomFromDate = (from) => {
    setCustomFromDate(from.target.value)
    if(customToDate) {
      let fromArr = from.target.value.split('-')
      let toArr = customToDate.split('-')
      const existingFilters = { ...selectedFilters };
      existingFilters['toDate'] = customToDate;
      existingFilters['fromDate'] = differenceInCalendarDays(new Date(toArr[0], toArr[1], toArr[2]), new Date(fromArr[0], fromArr[1], fromArr[2]))
      setSelectedFilters(existingFilters);
    }
  }

  const handleCustomToDate = (to) => {
    console.log(to.target.value)
    setCustomToDate(to.target.value)
    if(customFromDate) {
      let fromArr = customFromDate.split('-')
      let toArr = to.target.value.split('-')
      const existingFilters = { ...selectedFilters };
      existingFilters['toDate'] = to.target.value;
      existingFilters['fromDate'] = differenceInCalendarDays(new Date(toArr[0], toArr[1], toArr[2]), new Date(fromArr[0], fromArr[1], fromArr[2]))
      setSelectedFilters(existingFilters);
    }
  }
  const handleFilterClear = (filterName, filterValue) => {
    handleFilterClearUtil(
      filterName,
      filterValue,
      selectedFilters,
      setSelectedFilters,
    );
  };
  const handleExport = async () => {
    return safe7(selectedFilters, true);
  };

  const getData = async (selectedFilters) => {
    const stats = await safe7(selectedFilters);
    setServerData(stats);
    setIsLoading(false);
    setFiltersLoading(false)
  };

  if (isLoading) {
    return <Loader />;
  }

  console.log(serverData)

  const {
    barometers,
    completed_by,
    daily_average,
    safe_care_scores,
    survey_completions_breakdown
  } = serverData;



  return <Safe7jsx
    barometers={barometers}
    completedBy={completed_by}
    dailyAverage={daily_average}
    safeCareScores={safe_care_scores}
    surveyCompletionsBreakdown={survey_completions_breakdown}
    handleFilterChange={handleFilterChange}
    handleFilterClear={handleFilterClear}
    selectedFilters={selectedFilters}
    handleExport={handleExport}
    isLoading={filtersLoading}
    customDateActive={customDateActive}
    handleCustomFromDate={handleCustomFromDate}
    handleCustomToDate={handleCustomToDate}
  />;
}

export default Safe7;
