import React, { useState, useRef } from 'react';
import {
  Box,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Typography,
  Popover,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { capitalize } from '../../../util/text-utils';

import { ageFilter, dateFilter, genderFilter, participantFilter, genderIdentityFilter, travelFilter, pathwayTimeFilter, hospitalsVisitedFiter, testsFilter, sexualOrientationFilter, liveAloneFilter, ethnicityFilter, serviceTypeFilter } from './filterOptions';

import './FilterBlock.sass';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const renderSelectedFilter = (
  selectedFilter,
  filterName,
  handleFilterClear,
) => {
  return Array.isArray(selectedFilter) ? selectedFilter.map((filter) => {
    return (
      <div className={'active-filter'} key={filter.value}>
        {capitalize(filterName)}: {filter.label}
        <div className={'separator'} />
        <IconButton
          className={'close-icon'}
          onClick={() => handleFilterClear(filterName, filter.value)}
        >
          <CloseIcon />
        </IconButton>
      </div>
    );
  }) : <></> ;
};


export const FilterBlock = ({
  handleFilterChange,
  handleFilterClear,
  showParticipants,
  showDateRange,
  showAges,
  showGenders,
  showMore,
  selectedFilters,
  showCustomDate,
  handleCustomFromDate,
  handleCustomToDate
}) => {

  console.log(selectedFilters)

  const [filterSelectionOpen, setFilterSelectionOpen] = useState(false);

  const [showGenderIdentity, setShowGenderIdentity] = useState(false);
  const [showTransport, setShowTransport] = useState(false);
  const [showPathway, setShowPathway] = useState(false);
  const [showHospitals, setShowHospitals] = useState(false);
  const [showTests, setShowTests] = useState(false);
  const [showOrientation, setShowOrientation] = useState(false);
  const [showLiveAlone, setShowLiveAlone] = useState(false);
  const [showEthnicity, setShowEthnicity] = useState(false);
  const [showServiceType, setShowServiceType] = useState(false);


  const topfilterRow = useRef();
  const onAdvancedFilterSelectionChange = (event, updateChecboxState) => {
    updateChecboxState(event.target.checked);
  };


  const AdvancedFilterRow = ({filters}) =>
    filters.map((filter) => {
      return (
        <Grid item xs={4} key={filter.name}>
          <FormControlLabel
            control={
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={filter.isChecked}
                onChange={(event) => {
                  handleFilterClear(filter.name);
                  onAdvancedFilterSelectionChange(event, filter.onChange);
                }
                }
              />
            }
            label={filter.label}
          />
        </Grid>
      );
    });

  const generateSelect = ({ name, label, options, value }) => (
    <div className={'select'}>
      <InputLabel className='label'>{label}</InputLabel>
      <Select
        name={name}
        variant={'outlined'}
        className={'filter-select'}
        value={value || options[0].value}
        onChange={(event) => {
          handleFilterChange({
            value: event.target.value,
            name: event.target.name,
          });
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.label} className='item' value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );

  const generateMultiSelect = ({ name, label, options, values=[] }) => (
    <div className={'select'}>
      <InputLabel className='label'>{label}</InputLabel>
      <Autocomplete
        multiple
        id={name}
        name={name}
        options={options}
        onChange={(event, newValue) => {
          handleFilterChange({
            value: newValue,
            name,
          });
        }}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        renderTags={() => null}
        renderOption={(option) => {
          const selectedValues = values.map((value) => value.value);
          const isSelected = selectedValues.includes(option.value);
          return (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={isSelected}
              />
              {option.label}
            </React.Fragment>
          );
        }}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input
              {...params.inputProps}
              className={'filter-select-input'}
              placeholder={values.length ? values.map((value) => value.label) : 'All'}
            />
            <IconButton className={'down-icon'} onClick={() => { console.log('icon focus') }}>
              <ArrowDropDownIcon />
            </IconButton>
          </div>
        )}
      />
    </div>
  );

  return (
    <React.Fragment>
      <Grid container className={'top-filter-row'} ref={topfilterRow}>
        {showParticipants &&
          generateSelect({
            name: 'completed_by',
            label: 'Participants',
            options: participantFilter,
            value: selectedFilters['completed_by'],
          })}
        {showDateRange &&
          generateSelect({
            name: 'date',
            label: 'Date',
            options: dateFilter,
            value: selectedFilters['date'],
          })}
          {showCustomDate &&
              <Box mt={2.369} mr={2} display={'flex'} justifyContent={'center'}>
                <Box>
                <InputLabel className='label'>{'From:'}</InputLabel>
                <TextField onChange={handleCustomFromDate} type={'date'} variant={'outlined'}></TextField>
                </Box>
                <Box>
                <InputLabel className='label'>{'To:'}</InputLabel>
                <TextField onChange={handleCustomToDate}  type={'date'} variant={'outlined'}></TextField>
                </Box>
              </Box>
          }
        {showAges &&
          generateMultiSelect({
            name: 'age',
            label: 'Age',
            options: ageFilter,
            values: selectedFilters['age'],
          })}
        {showGenders &&
          generateMultiSelect({
            name: 'gender',
            label: 'Gender',
            options: genderFilter,
            values: selectedFilters['gender'],
          })}
        {showMore && (
          <Grid xs={3}>
            <Button onClick={() => setFilterSelectionOpen(true)} className={'more-filters-btn'}>
              More Filters
            </Button>
          </Grid>
        )}
      </Grid>
      <Popover
        open={filterSelectionOpen}
        anchorEl={topfilterRow ? topfilterRow.current : undefined}
        onClose={() => setFilterSelectionOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { width: '100%' },
        }}
        style={{
          width:
            topfilterRow && topfilterRow.current
              ? topfilterRow.current.clientWidth
              : undefined,
        }}
      >
        <Grid container className={'advanced-filter-options'}>
          <Grid container item xs={12} spacing={3}>
            <AdvancedFilterRow
              filters={[
                         {
                  name: 'Clinic Area',
                  label: 'Clinic Area',
                  isChecked: showServiceType,
                  onChange: setShowServiceType,
                },
                {
                  name: 'genderIdentity',
                  label: 'Gender Identity',
                  isChecked: showGenderIdentity,
                  onChange: setShowGenderIdentity,
                },
       
                {
                  name: 'transport',
                  label: 'Transport',
                  isChecked: showTransport,
                  onChange: setShowTransport,
                },
                
              ]}
            />
          </Grid>

          <Grid container item xs={12} spacing={3}>
            <AdvancedFilterRow
              filters={[
                    {
                  name: 'orientation',
                  label: 'Sexual Orientation',
                  isChecked: showOrientation,
                  onChange: setShowOrientation,
                },
                {
                  name: 'liveAlone',
                  label: 'Live alone',
                  isChecked: showLiveAlone,
                  onChange: setShowLiveAlone,
                },
                {
                  name: 'ethnicity',
                  label: 'Ethnic group',
                  isChecked: showEthnicity,
                  onChange: setShowEthnicity,
                },
              ]}
            />
          </Grid>
        </Grid>
      </Popover>
      {(showServiceType || showGenderIdentity ||
        showTransport ||
        showOrientation ||
        showLiveAlone ||
        showEthnicity) && (
        <Grid container className={'advanced-filter-row'}>
          <Grid xs={12}>
            <Typography variant={'h6'}>Advanced Filters</Typography>
          </Grid>
          {showServiceType &&
            generateMultiSelect({
              name: 'serviceType',
              label: 'Service Type',
              options: serviceTypeFilter,
              values: selectedFilters['serviceType'],
            })}
          {showGenderIdentity &&
            generateMultiSelect({
              name: 'genderIdentity',
              label: 'Gender identity',
              options: genderIdentityFilter,
              values: selectedFilters['genderIdentity'],
            })}
          {showTransport &&
            generateMultiSelect({
              name: 'transport',
              label: 'Transport',
              options: travelFilter,
              values: selectedFilters['transport'],
            })}
          {showPathway &&
            generateMultiSelect({
              name: 'pathway',
              label: 'Pathway time',
              options: pathwayTimeFilter,
              values: selectedFilters['pathway'],
            })}
          {showHospitals &&
            generateMultiSelect({
              name: 'hospitals',
              label: 'Hospitals Visited',
              options: hospitalsVisitedFiter,
              values: selectedFilters['hospitals'],
            })}
          {showTests &&
            generateMultiSelect({
              name: 'tests',
              label: 'Tests Recieved',
              options: testsFilter,
              values: selectedFilters['tests'],
            })}
          {showOrientation &&
            generateMultiSelect({
              name: 'orientation',
              label: 'Sexual Orientation',
              options: sexualOrientationFilter,
              values: selectedFilters['orientation'],
            })}
          {showLiveAlone &&
            generateMultiSelect({
              name: 'liveAlone',
              label: 'Live Alone',
              options: liveAloneFilter,
              values: selectedFilters['liveAlone'],
            })}
          {showEthnicity &&
            generateMultiSelect({
              name: 'ethnicity',
              label: 'Ethnicity',
              options: ethnicityFilter,
              values: selectedFilters['ethnicity'],
            })}
        </Grid>
      )}

      <Grid container className={'selected-filter-row'}>
        {Object.keys(selectedFilters).map((filterName) =>
          renderSelectedFilter(
            selectedFilters[filterName],
            filterName,
            handleFilterClear,
          ),
        )}
      </Grid>
    </React.Fragment>
  );
};
