import React from 'react';
import MainContentWrapper from '../../components/wrappers/MainContent/MainContent';
import { Box, Typography } from '@material-ui/core';
import Heading from '../../components/heading/Heading'
import BlockWrapper from '../../components/wrappers/Block/Block';
import { FilterBlock } from '../../components/blocks/filter/FilterBlock';
import BarometerBlockContainer from '../../components/blocks/barometer/BarometerBlockContainer';
import EngagementScoreBlock from '../../components/blocks/EngagementScoreBlock';
import { getBarometerFill } from '../../util/filter-utils';
import DataTable from '../../components/data-table/DataTable';

const Safe7Jsx = ({
  barometers,
  completedBy,
  dailyAverage,
  safeCareScores,
  surveyCompletionsBreakdown,
  handleFilterChange,
  handleFilterClear,
  selectedFilters,
  handleExport,
  isLoading,
  customDateActive,
  handleCustomFromDate,
  handleCustomToDate
}) => {

  console.log(surveyCompletionsBreakdown)

  return (
    <MainContentWrapper isLoading={isLoading}>
      <Heading showExport handleExport={handleExport}>Safe-7 Barometers</Heading>
 
      <BlockWrapper>
        <FilterBlock
          showParticipants
          showDateRange
          showAges
          showGenders
          showMore
          showCustomDate={customDateActive}
          handleCustomFromDate={handleCustomFromDate}
          handleCustomToDate={handleCustomToDate}
          handleFilterChange={handleFilterChange}
          selectedFilters={selectedFilters}
          showServiceType
          // handleFilterChange={handleFilterChange}
          handleFilterClear={handleFilterClear}
        />
      </BlockWrapper>
      {barometers.safe_care &&
        <>
        <EngagementScoreBlock
          overallEngagementScore={[
            {
              "name": "Non Score",
              "value": 10-safeCareScores.average_score,
              "fill": "#eee"
            },
            {
            "name": 'Score',
            "value": safeCareScores.average_score,
            "fill": getBarometerFill(safeCareScores.average_score)
            }
          ]}
          lineChartData={dailyAverage.map((dailyAv) => { return { name: dailyAv.date, value: dailyAv.average } })}
          completedBy={completedBy}
          barometers={barometers}
          minMaxScores={safeCareScores}
          averageScore={barometers.safe_care ? barometers.safe_care.current : '--'}
        />
        <BarometerBlockContainer
          title={'Overall Breakdown'}
          barometers={barometers}
          linkToQuestions
        />
        </>
    }
    {!barometers.safe_care &&
      <BlockWrapper>
      <Box my={12}>
      <Typography variant={'h5'} align={"center"} className={'grey-font'}>There is no data to display for this filter</Typography>
      </Box>
      </BlockWrapper>
    }
    {surveyCompletionsBreakdown?.length && 
        <BlockWrapper>
          <Box mb={3}><Typography variant={'h5'}>Survey Completions by Clinic Area</Typography></Box>
          <DataTable data={{
            title: {
              name: 'Clinic Area',
              value: 'Completed'
            },
            items: surveyCompletionsBreakdown
          }} />
        </BlockWrapper>}
     
    </MainContentWrapper>
  );
}

export default Safe7Jsx;
