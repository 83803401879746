import React, { useState, useEffect } from 'react';
import './Dashboard.sass';
import DashboardJsx from './Dashboard.jsx';
import { dashboardOverview } from '../../api';
import Loader from '../Loader/loader';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';


function Dashboard() {
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filtersLoading, setFiltersLoading] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState({
    date: 30,
  });
  const [customDateActive, setCustomDateActive] = useState(false)
  const [exportCSVData, setExportCSVData] = useState();
  const [serverData, setServerData] = useState({
    age_breakdown: {},
    barometers: {},
    completed_by: {},
    daily_average: {},
    deprivation_breakdown: {},
    gender_breakdown: {},
    survey_invitations: {},
  });
   const [customFromDate, setCustomFromDate] = useState()
   const [customToDate, setCustomToDate] = useState()

  useEffect(() => {
    if(selectedFilters.date !== 'custom') {
      getDashboardData(selectedFilters);
       setCustomDateActive(false)
    } else {
      setCustomDateActive(true)
      if(customToDate && customFromDate) {
        setFiltersLoading(true)
        getDashboardData(selectedFilters);
      } else {
        setFiltersLoading(false)
      }
    }
  }, [selectedFilters]);

  const getDashboardData = async (selectedFilters) => {
    const dashboardData = await dashboardOverview(selectedFilters);
    console.log(dashboardData)
    setServerData(dashboardData);
    setIsLoading(false);
    setFiltersLoading(false)
  };

  const handleOpen = () => {
    setSupportModalOpen(true);
  }

  const handleClose = () => {
    setSupportModalOpen(false);
  }

  const handleFilterChange = (updatedFilter) => {
      setFiltersLoading(true)
      const existingFilters = { ...selectedFilters };
      existingFilters[updatedFilter.name] = updatedFilter.value;
      setSelectedFilters(existingFilters);
      if(updatedFilter == '1' || updatedFilter == '7' || updatedFilter == '30' || updatedFilter == '90' ) {
        setCustomDateActive(false)
      }
  }

  const handleCustomFromDate = (from) => {
    setCustomFromDate(from.target.value)
    if(customToDate) {
      let fromArr = from.target.value.split('-')
      let toArr = customToDate.split('-')
      const existingFilters = { ...selectedFilters };
      existingFilters['toDate'] = customToDate;
      existingFilters['fromDate'] = differenceInCalendarDays(new Date(toArr[0], toArr[1], toArr[2]), new Date(fromArr[0], fromArr[1], fromArr[2]))
      setSelectedFilters(existingFilters);
    }
  }

  const handleCustomToDate = (to) => {
    setCustomToDate(to.target.value)
    if(customFromDate) {
      let fromArr = customFromDate.split('-')
      let toArr = to.target.value.split('-')
      const existingFilters = { ...selectedFilters };
      existingFilters['toDate'] = to.target.value;
      existingFilters['fromDate'] = differenceInCalendarDays(new Date(toArr[0], toArr[1], toArr[2]), new Date(fromArr[0], fromArr[1], fromArr[2]))
      setSelectedFilters(existingFilters);
    }
  }

  const handleExport = async () => {
    return dashboardOverview(selectedFilters, true);
  }

  if (isLoading) {
    return <Loader />
  }

  const {
    age_breakdown,
    barometers,
    completed_by,
    daily_average,
    deprivation_breakdown,
    gender_breakdown,
    survey_invitations,
  } = serverData;

  return (
    <DashboardJsx
      handleClose={handleClose}
      handleOpen={handleOpen}
      handleExport={handleExport}
      handleFilterChange={handleFilterChange}
      open={supportModalOpen}
      ageBreakdown={age_breakdown}
      barometers={barometers}
      completedBy={completed_by}
      dailyAverage={daily_average}
      deprivationBreakdown={deprivation_breakdown}
      genderBreakdown={gender_breakdown}
      surveyInvitations={survey_invitations}
      selectedFilters={selectedFilters}
      isLoading={filtersLoading}
      customDateActive={customDateActive}
      handleCustomFromDate={handleCustomFromDate}
      handleCustomToDate={handleCustomToDate}
    />
  );
}

export default Dashboard;
