import React from 'react';
import {Link} from 'react-router-dom';
import {Typography, Grid, Box, Button, TextField, InputLabel, CircularProgress} from '@material-ui/core';

export const LoginJsx = (props) => {
return (
<div className={`login`}>
  <Grid container spacing={0}>
    <Grid item xs={12} className='right-col'>
    <form className={`form-container ${props.fadedLeft ? 'faded-left' : 'not-faded'} ${props.fadedRight ? 'faded-right' : 'not-faded'}`} onSubmit={(e) => props.handleSubmit(e)}>
      <div className='logo-round'></div>
      <Box m={2.369} mb={4.728}>
      <Typography variant="h4" className="form-title primary-font">
      <strong>Sign In</strong>
      </Typography>
      </Box>
        <Box m={1} className='form-group'>
          <InputLabel className='form-label primary-font'>Username or Email Address</InputLabel>
          <TextField variant="outlined" size="small" id="loginEmail" className='full-width input-theme' type='text' onKeyUp={(e) => props.setFormValues(e)}/>
        </Box>
        <Box m={1} className='form-group'>
          <InputLabel className='form-label primary-font'>Password</InputLabel>
          <TextField variant="outlined" size="small" id="loginPassword" className='full-width input-theme' type='password' onKeyUp={(e) => props.setFormValues(e)}/>
        </Box>
        <Box my={2.369} mx={1} display="flex" flexDirection="center">
        {
        !props.isLoading && <Button variant="contained" align="center" className='btn-theme full-width' type='submit'><strong>Log In</strong></Button>
        }
        {
        props.isLoading && <CircularProgress className="centered" />
        }
        </Box>
        <Box m={2.369} display="flex" flexDirection="center">
        <Link className='form-link secondary-font full-width' to="/forgot-password">Forgot Your Password?</Link>
        </Box>
        {<InputLabel className='error-label form-link full-width'>{props.loginErr}</InputLabel>}
        <Box mb={2.369}>
        <div className='nhs-logo'></div>
        </Box>
      </form>
      </Grid>
  </Grid>
</div>
)
}

export default LoginJsx;
