export const ageFilter = [
  { label: '<20', value: '<20' },
  { label: '20-29', value: '20-29' },
  { label: '30-39', value: '30-39' },
  { label: '40-49', value: '40-49' },
  { label: '50-59', value: '50-59' },
  { label: '60-69', value: '60-69' },
  { label: '70-79', value: '70-79' },
  { label: '80-89', value: '80-89' },
  { label: '90+', value: '90+' },
];

export const dateFilter = [
  { label: 'Today', value: '1' },
  { label: 'Last 7 days', value: '7' },
  { label: 'Last 30 days', value: '30' },
  { label: 'Last 90 days', value: '90' },
  { label: 'Custom', value: 'custom' },
];

export const genderFilter = [
  { label: 'Female', value: 'female' },
  { label: 'Male', value: 'male' },
];
export const participantFilter = [
  { label: 'All', value: 'all'},
  { label: 'Patient', value: 'patient' },
  { label: 'Family Member or Carer', value: 'other' },
];

export const genderIdentityFilter = [
  { label: 'Man (including trans man)', value: 'man' },
  { label: 'Woman (including trans woman)', value: 'woman' },
  { label: 'Non-binary', value: 'non_binary' },
  { label: 'In another way', value: 'other' },
  { label: 'Prefer not to say', value: 'undisclosed' },
];

export const travelFilter = [
  { label: 'Drive my own vehicle (car/motorbike)', value: 'own_vehicle' },
  { label: 'Public transport (bus, train, etc)', value: 'public' },
  { label: 'Taxi', value: 'taxi' },
  { label: 'Family / friend drives me', value: 'relative' },
  { label: 'Hospital transport service', value: 'hospital_transport' },
  { label: 'Others', value: 'others' },
];

export const pathwayTimeFilter = [
  { label: '1 day', value: '1' },
  { label: '7 days', value: '7' },
  { label: '20 days', value: '20' },
  { label: '30 days', value: '30' },
];

export const hospitalsVisitedFiter = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6+', value: '6+' },
  { label: `I'm not sure`, value: 'unsure' },
];

export const testsFilter = [
  { label: 'CT scan of the lungs', value: 'lungs_ct' },
  { label: 'PET scan', value: 'pet_scan' },
  { label: 'Brain scan', value: 'brain_scan' },
  { label: 'Bronchoscopy', value: 'bronchoscopy' },
  { label: 'EBUS', value: 'ebus' },
  { label: 'Lung biopsy', value: 'lung_biopsy' },
  {
    label: 'CT scan or MRI scan of a different part of the body',
    value: 'other_ct_or_mri',
  },
  { label: 'A different kind of biopsy', value: 'other_biopsy' },
  { label: 'Breathing tests', value: 'breathing' },
  { label: 'Heart scan', value: 'heart_scan' },
  { label: 'Exercise test', value: 'exercise' },
  { label: `I\'m not sure`, value: 'unsure' },
  { label: 'Others', value: 'others' },
];

export const sexualOrientationFilter = [
  { label: 'Straight/Heterosexual', value: 'heterosexual' },
  { label: 'Homosexual, lesbian, gay', value: 'homosexual' },
  { label: 'Bisexual', value: 'bisexual' },
  { label: 'Prefer not to say', value: 'undisclosed' },
];

export const liveAloneFilter = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
  { label: 'Prefer not to say', value: 'undisclosed' },
];

export const ethnicityFilter = [
  { label: 'White', value: 'white' },
  { label: 'White and Black Caribbean', value: 'white_black_caribbean' },
  { label: 'White and Black African', value: 'white_black_african' },
  { label: 'White and Asian', value: 'white_asian' },
  { label: 'Any other Mixed / Multiple ethnic background', value: 'mixed' },
  { label: 'Asian - Indian', value: 'asian_indian' },
  { label: 'Asian - Pakistani', value: 'asian_pakistani' },
  { label: 'Asian - Bangladeshi', value: 'asian_bangladeshi' },
  { label: 'Asian - Chinese', value: 'asian_chinese' },
  { label: 'Asian - Any other Asian background', value: 'asian_other' },
  { label: 'African', value: 'african' },
  { label: 'Caribbean', value: 'caribbean' },
  {
    label: 'Other Black / African / Caribbean background',
    value: 'black_african_caribbean_other',
  },
  { label: 'Arab', value: 'arab' },
  { label: 'Any other ethnic group', value: 'other' },
];


export const serviceTypeFilter = [
  { label: 'RAPID hub: morning', value: 'rapid_hub_morning' },
  { label: 'Post-MDT clinic', value: 'post_mdt_clinic' },
  { label: 'Survivorship clinic', value: 'survivorship_clinic' },
  { label: 'Pleural clinic', value: 'pleural_clinic' },
  { label: 'Pleural procedure list', value: 'pleural_procedure_list' },
  { label: 'RAPID hub: other', value: 'rapid_hub_other' },
  { label: 'Bronchoscopy procedure', value: 'bronchoscopy_procedure' },
  { label: 'Lung biopsy procedure', value: 'lung_biopsy_procedure' },
  { label: 'Oncology clinic: first appt', value: 'oncology_clinic_first' },
  { label: 'Oncology clinic: follow-up appt', value: 'oncology_clinic_follow_up' },
  { label: 'Cecelia Unit', value: 'cecelia_unit' },
  { label: 'Lung Surgery: first appt', value: 'lung_surgery_clinic_first' },
  { label: 'Lung Surgery: follow-up appt', value: 'lung_surgery_clinic_follow_up' },
  { label: 'One-stop lung cancer clinic', value: 'one_stop_lung_cancer' },
];
