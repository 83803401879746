import React from 'react';
import MainContentWrapper from '../../components/wrappers/MainContent/MainContent';
import { Box, Typography } from '@material-ui/core';
import BlockWrapper from '../../components/wrappers/Block/Block';
import Heading from '../../components/heading/Heading';
import { FilterBlock } from '../../components/blocks/filter/FilterBlock';
import { LineChartBlock } from '../../components/charts/LineChartBlock';
import EngagementScoreBlock from '../../components/blocks/EngagementScoreBlock';
import GenderBlock from '../../components/blocks/GenderBlock';
import { getBarometerFill } from '../../util/filter-utils';

const Safe7QuestionJsx = ({
  questionHeader,
  selectedFilters,
  handleFilterChange,
  handleFilterClear,
  handleExport,
  completedBy,
  dailyAverage,
  questionScores,
  genderBreakdown,
  ageBreakdown,
  overallScoresBreakdown,
  isLoading,
  customDateActive,
  handleCustomFromDate,
  handleCustomToDate
}) => {
  return <MainContentWrapper isLoading={isLoading}>
    <Heading safe7Question showExport handleExport={handleExport}>{questionHeader}</Heading>
      <BlockWrapper>
        <FilterBlock
          showParticipants
          showDateRange
          showAges
          showGenders
          showMore
          showServiceType
          showCustomDate={customDateActive}
          handleCustomFromDate={handleCustomFromDate}
          handleCustomToDate={handleCustomToDate}
          selectedFilters={selectedFilters}
          handleFilterChange={handleFilterChange}
          handleFilterClear={handleFilterClear}
      />
    </BlockWrapper>
    {questionScores.average_score &&
    <>
    <EngagementScoreBlock
      overallEngagementScore={[
        {
          "name": "Non Score",
          "value": 10-questionScores.average_score,
          "fill": "#eee"
        },
        {
        "name": 'Score',
        "value": questionScores.average_score,
        "fill": getBarometerFill(questionScores.average_score)
        }
      ]}
      lineChartData={dailyAverage.map((dailyAv) => { return { name: dailyAv.date, value: dailyAv.average } })}
      completedBy={completedBy}
      averageScore={questionScores.average_score}
      minMaxScores={questionScores}
    />
    <GenderBlock
      genderBreakdown={genderBreakdown}
      ageBreakdown={ageBreakdown}
      ageTitle={'Age Ranges'}
      ageRange
    />
    <BlockWrapper>
      <LineChartBlock barData={overallScoresBreakdown.map((breakdown) => { return {name: breakdown.score, value: breakdown.count}})}/>
    </BlockWrapper>
    </>
    }
    {!questionScores.average_score &&
      <BlockWrapper>
      <Box my={12}>
      <Typography variant={'h5'} align={"center"} className={'grey-font'}>There is no data to display for this filter</Typography>
      </Box>
      </BlockWrapper>
    }
  </MainContentWrapper>
}

export default Safe7QuestionJsx;
