import React, { useState,  useEffect, useRef } from 'react';
import ProfileJsx from './Profile.jsx'
import Loader from '../Loader/loader.jsx';
import { profile, resetPassword } from '../../api';

const Profile = () => {
  const [profileData, setProfileData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [passwordTooWeak, setPasswordTooWeak] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const passwordFieldRef = useRef();
  const repeatPasswordFieldRef = useRef();
  useEffect(() => {
    getProfileData();
  }, [])

  const getProfileData = async () => {
    const profileData = await profile();
    setProfileData(profileData.profile)
    setIsLoading(false);
  }

  const checkPasswordValidity = (password) => {
    /*
    At least 1 special char
    At least 2 upper case char
    At least 1 lowercase char
    At least 1 number
    Min length of 12 chars
    */
      return !(
        !password ||
        !password.match(
          /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{12,}$/,
        )
      );
  }

    const handleModalClose = () => {
      setSuccessModalOpen(false);
    };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    const passwordFieldValue = passwordFieldRef.current.value;
    const repeatPasswordFieldValue = repeatPasswordFieldRef.current.value;

    if (!passwordFieldValue) {
      setErrorMessage('Please enter a password')
      return;
    }
      if (passwordFieldValue !== repeatPasswordFieldValue) {
        setErrorMessage('Passwords do not match');
        return;
      }
    const isPasswordValid = checkPasswordValidity(passwordFieldValue);
    if (!isPasswordValid) {
      setErrorMessage('Password too weak');
      setPasswordTooWeak(true);
      return;
    }
    const response = await resetPassword({
      password: passwordFieldValue,
      confirm_password: repeatPasswordFieldValue,
    });
    if (response.data && response.data.success) {
      setErrorMessage('');
      setPasswordTooWeak(false);
      setSuccessModalOpen(true);
    }
  }

  if (isLoading) {
    return <Loader />;
  }


  return (
    <ProfileJsx
      profileData={profileData}
      handlePasswordSubmit={handlePasswordSubmit}
      passwordFieldRef={passwordFieldRef}
      repeatPasswordFieldRef={repeatPasswordFieldRef}
      errorMessage={errorMessage}
      passwordTooWeak={passwordTooWeak}
      successModalOpen={successModalOpen}
      handleModalClose={handleModalClose}
    />
  );
}

export default Profile
