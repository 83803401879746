import React, {useState, useEffect} from 'react';
import './ForgotPassword.sass';
import ForgotPasswordJsx from './ForgotPassword.jsx';

import axios from 'axios';

function ForgotPassword() {

  const [email, setEmail] = useState('');
  const [emailTouched, setEmailTouched] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [authErr, setAuthErr] = useState('');
  const [authSuccess, setAuthSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      email: email
    }
    setIsLoading(true);
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/forgot-password`, data)
      .then(res => {
        console.log(res);
        setIsLoading(false);
        if(res.status == 200) {
          setAuthSuccess(true)
        }
      }).catch(err => {
        setAuthErr(err.response.data.error_message)
        setIsLoading(false);
      })
  }

  const setFormValues = (e) => {
    if(e.target.id === 'requestEmail') {
      setAuthErr('');
      setEmail(e.target.value);
      setEmailTouched(true);
    }
  }

  useEffect(() => {
    validateForm();
  }, setFormValues)

  const validateForm = () => {
    setAuthErr('')
    if(!email && emailTouched) {
      setAuthErr('Email is required')
    }
  }

  return(
    <div>
      <ForgotPasswordJsx
      handleSubmit={handleSubmit}
      setFormValues={setFormValues}
      isLoading={isLoading}
      authErr={authErr}
      authSuccess={authSuccess}
      />
    </div>
  )
}

export default ForgotPassword;
