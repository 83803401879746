import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import MainContentWrapper from '../../components/wrappers/MainContent/MainContent';
import Heading from '../../components/heading/Heading'
import BlockWrapper from '../../components/wrappers/Block/Block';
import { FilterBlock } from '../../components/blocks/filter/FilterBlock';
import { PieChartBlock } from '../../components/charts/PieChartBlock';
import {LineChartBlock} from '../../components/charts/LineChartBlock';
import { BarChartBlock } from '../../components/charts/BarChartBlock';
import GenderBlock from '../../components/blocks/GenderBlock';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { capitalize, normaliseDBString } from '../../util/text-utils';


const StatsJsx = ({
  ageBreakdown,
  genderBreakdown,
  livingBreakdown,
  travelBreakdown,
  travel_word_cloud,
  deprivationBreakdown,
  ethnicityBreakdown,
  genderIdentityBreakdown,
  sexualOrientalBreakdown,
  selectedFilters,
  handleFilterChange,
  handleFilterClear,
  handleExport,
  isLoading,
  customDateActive,
  handleCustomFromDate,
  handleCustomToDate
}) => {

  const generalLivingAlone = livingBreakdown && Object.keys(livingBreakdown).length ? [
    {
      "name": "Yes",
      "value": livingBreakdown.yes.percentage,
      "fill": "#7CB45D"
    },
    {
      "name": "No",
      "value": livingBreakdown.no.percentage,
      "fill": "#4B6195"
    },
    {
      "name": "Prefer Not To Say",
      "value": livingBreakdown.undisclosed.percentage,
      "fill": "#4B6195"
    }
  ] : undefined;

  return (
    <MainContentWrapper isLoading={isLoading}>
      <Heading showExport handleExport={handleExport}>General Status</Heading>
      <BlockWrapper>
        <FilterBlock
          showDateRange
          showAges
          showGenders
          showMore
          showParticipants
          showCustomDate={customDateActive}
          handleCustomFromDate={handleCustomFromDate}
          handleCustomToDate={handleCustomToDate}
          selectedFilters={selectedFilters}
          handleFilterChange={handleFilterChange}
          handleFilterClear={handleFilterClear}
        />
      </BlockWrapper>
      {ageBreakdown.length >= 1 &&
        <>
      <GenderBlock
        ageBreakdown={ageBreakdown}
        genderBreakdown={genderBreakdown}
        ageTitle={'Age'}
      />

    

      <BlockWrapper>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box my={1}>
              <Typography variant={'h6'}>Living Alone</Typography>
            </Box>

            <Box className={'theme-box'} mr={1}>
              {
                generalLivingAlone &&
                <>
                  <PieChartBlock pieData={generalLivingAlone} />
                  <Box display={'flex'} justifyContent={'space-around'}>
                      <Typography variant={'subtitle1'} align={'center'}><strong><FiberManualRecordIcon fontSize={'small'} style={{color: "#7CB45D"}} className={'responder-icon'}/> Yes</strong></Typography>
                    <Typography variant={'subtitle1'} align={'center'}>{`${livingBreakdown.yes.percentage}% (${livingBreakdown.yes.count})`}</Typography>
                  </Box>
                  <Box display={'flex'} justifyContent={'space-around'}>
                      <Typography variant={'subtitle1'} align={'center'}><strong><FiberManualRecordIcon fontSize={'small'} style={{color: "#4B6195"}} className={'responder-icon'}/> No</strong></Typography>
                      <Typography variant={'subtitle1'} align={'center'}>{`${livingBreakdown.no.percentage}% (${livingBreakdown.no.count})`}</Typography>
                  </Box>
                  <Box display={'flex'} justifyContent={'space-around'}>
                      <Typography variant={'subtitle1'} align={'center'}><strong><FiberManualRecordIcon fontSize={'small'} style={{color: "#4B6195"}} className={'responder-icon'}/> Prefer Not To Say</strong></Typography>
                      <Typography variant={'subtitle1'} align={'center'}>{`${livingBreakdown.undisclosed.percentage}% (${livingBreakdown.undisclosed.count})`}</Typography>
                  </Box>
                </>
              }
            </Box>
          
          </Grid>
          <Grid item xs={8}>
            <Box my={1}>
            <Typography variant={'h6'}>Travel to appointments</Typography>
            </Box>
            <Box className={'theme-box'}>
              <BarChartBlock
                height={459}
                barData={travelBreakdown.map(travel => { return { name: normaliseDBString(travel.choice), value: travel.count } })}
              />
              <Typography variant={'subtitle1'}><strong>Other types of travel</strong></Typography>
              <Grid container className={'theme-box-bg other-list-item'}>
                <Grid item xs={2}><Typography>Type</Typography></Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={2}><Typography>No. of people</Typography></Grid>
              </Grid>
              {
                travel_word_cloud.length > 0 && travel_word_cloud.map(({word, count}) => (
                  <Grid container className={'other-list-item'}>
                    <Grid item xs={2} style={{textTransform: 'capitalize'}}><Typography>{word}</Typography></Grid>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={2}><Typography>{count}</Typography></Grid>
                  </Grid>
                ))
              }
              {
                travel_word_cloud.length === 0 &&
                <Grid container className={'other-list-item'}>
                  <Grid item xs={12} style={{textAlign: 'center'}}>No data</Grid>
                </Grid>
              }
            </Box>
          </Grid>
        </Grid>
      </BlockWrapper>
        {!!deprivationBreakdown && <BlockWrapper>
          <Grid container>
            <Grid item xs={6}>
              <Box mb={3.109}>
                <Typography variant={'h5'}>Deprivation</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <BarChartBlock
              barData={deprivationBreakdown.map((deprivation) =>  ({ name:  deprivation.score, value: deprivation.count }))}
              YAxisMaxDomain={100}
            />
          </Grid>
        </BlockWrapper>
        }
        {!!ethnicityBreakdown && <BlockWrapper>
          <Grid container>
            <Grid item xs={6}>
              <Box mb={3.109}>
                <Typography variant={'h5'}>Ethnicity</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <BarChartBlock 
              // useCustomTick={true}
              height={650}
              isVertical={true}
              barData={ethnicityBreakdown.map((ethinicity) => ({ name: normaliseDBString(ethinicity.choice), value: ethinicity.count }))}
              YAxisMaxDomain={100}
            />
          </Grid>
        </BlockWrapper>
        }
        {!!genderIdentityBreakdown && <BlockWrapper>
          <Grid container>
            <Grid item xs={6}>
              <Box mb={3.109}>
                <Typography variant={'h5'}>Gender Identity</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <BarChartBlock
              barData={genderIdentityBreakdown.map((genderIdentity) =>  ({ name: normaliseDBString(genderIdentity.choice) , value: genderIdentity.count }))}
              YAxisMaxDomain={100}
            />
          </Grid>
        </BlockWrapper>
        }
        {!!sexualOrientalBreakdown && <BlockWrapper>
          <Grid container>
            <Grid item xs={6}>
              <Box mb={3.109}>
                <Typography variant={'h5'}>Sexual Orientation</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <BarChartBlock
              barData={sexualOrientalBreakdown.map((sexualOrientation) =>  ({ name: normaliseDBString(sexualOrientation.choice) , value: sexualOrientation.count }))}
              YAxisMaxDomain={100}
            />
          </Grid>
        </BlockWrapper>
        }
      </>
  }
    </MainContentWrapper>
  );
}

export default StatsJsx;
