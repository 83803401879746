import React from 'react'
import PropTypes from 'prop-types'
import DataTableJsx from './DataTableJsx'

const DataTable = ({data}) => {
  return (
    <DataTableJsx data={data} />
  )
}

DataTable.propTypes = {}

export default DataTable