import React from 'react';
import './MainContent.sass';
const MainContentWrapper = ({ children, isLoading }) => {
  return (
    <div className='background-container'>
      <div className={`main-container auth ${isLoading ? 'loading-main' : ''}`}>
        {children}
      </div>
    </div>
  );
}

export default MainContentWrapper;
