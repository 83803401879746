import React, { useState } from 'react';
import * as EmailValidator from 'email-validator';
import InviteJSX from './Invite.jsx';
import { createSurveyInvitation } from '../../api';

const Invite = () => {
  const [selectValue, setSelectValue] = useState('accepted');
  const [emailValue, setEmailValue] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const handleInviteSubmission = async (event) => {
    event.preventDefault();
    
    const emailValid = EmailValidator.validate(emailValue);
    if (!emailValid && selectValue === 'accepted') {
      setEmailInvalid(true);
      return;
    }
    setEmailInvalid(false);
    const response = await createSurveyInvitation({
      response: selectValue,
      email: selectValue === 'accepted' ? emailValue : undefined,
    });

    if (response.data && response.data.success) {
      setSelectValue('accepted');
      setEmailInvalid(false);
      setEmailValue('');
      setSuccessModalOpen(true);
    }
  }

  const handleSelectChange = (event) => {
    if (event.target.value === 'declined') {
      setEmailInvalid(false);
    }
    setSelectValue(event.target.value);
  }

  const handleEmailChange = (event) => {
    setEmailValue(event.target.value);
  }

  const handleModalClose = () => {
    setSuccessModalOpen(false)
  }

  return (
    <InviteJSX
      handleInviteSubmission={handleInviteSubmission}
      handleSelectChange={handleSelectChange}
      selectValue={selectValue}
      emailValue={emailValue}
      handleEmailChange={handleEmailChange}
      emailInvalid={emailInvalid}
      successModalOpen={successModalOpen}
      handleModalClose={handleModalClose}
    />
  );
}

export default Invite
