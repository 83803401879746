import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Safe7Questionjsx from './Safe7Question.jsx';
import Loader from '../Loader/loader';
import { safe7Question } from '../../api';
import {
  handleFilterChangeUtil,
  handleFilterClearUtil,
} from '../../util/filter-utils';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';


const getQuestionHeader = (question) => {
  if (question === 'safe-care') {
    return 'Overall, how safe did you feel under our care?';
  } else if (question === 'safe-speed') {
    return 'How satisfied were you with the speed of service?';
  } else if (question === 'safe-kind') {
    return 'How kind were the team that cared for you?';
  } else if (question === 'safe-access') {
    return 'How easily were you able to access the team if you needed them?';
  } else if (question === 'safe-clarity') {
    return 'How clear was the information you were provided with by our team?';
  } else if (question === 'safe-trust') {
    return 'How would you rate your level of trust and confidence in the team that cared for you?';
  } else if (question === 'safe-listened') {
    return 'How would you rate how well we listened to you and your worries/concerns?';
  }
}

const Safe7Question = () => {
  const [serverData, setServerData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [filtersLoading, setFiltersLoading] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState({
    date: 30,
    completed_by: 'patient',
    age: [],
  });
  const [customDateActive, setCustomDateActive] = useState(false)
  const [customFromDate, setCustomFromDate] = useState()
  const [customToDate, setCustomToDate] = useState()
  const { questionParm } = useParams();

  const questionHeader = getQuestionHeader(questionParm);
  useEffect(() => {
    if(selectedFilters.date !== 'custom') {
      getData(selectedFilters);
       setCustomDateActive(false)
    } else {
      setCustomDateActive(true)
      if(customToDate && customFromDate) {
        setFiltersLoading(true)
        getData(selectedFilters);
      } else {
        setFiltersLoading(false)
      }
    }
  }, [selectedFilters]);

  // useEffect(() => {
  //   getExistingFilters();
  // }, [])

  const getExistingFilters = async () => {
    const existingFilters = await localStorage.getItem('filters');
    setSelectedFilters(JSON.parse(existingFilters));
  }
  const handleFilterChange = (updatedFilter) => {
    handleFilterChangeUtil(updatedFilter, selectedFilters, setSelectedFilters);
    setFiltersLoading(true)
    if(updatedFilter == '1' || updatedFilter == '7' || updatedFilter == '30' || updatedFilter == '90' ) {
      setCustomDateActive(false)
    }
  };

  const handleCustomFromDate = (from) => {
    setCustomFromDate(from.target.value)
    if(customToDate) {
      let fromArr = from.target.value.split('-')
      let toArr = customToDate.split('-')
      const existingFilters = { ...selectedFilters };
      existingFilters['toDate'] = customToDate;
      existingFilters['fromDate'] = differenceInCalendarDays(new Date(toArr[0], toArr[1], toArr[2]), new Date(fromArr[0], fromArr[1], fromArr[2]))
      setSelectedFilters(existingFilters);
    }
  }

  const handleCustomToDate = (to) => {
    console.log(to.target.value)
    setCustomToDate(to.target.value)
    if(customFromDate) {
      let fromArr = customFromDate.split('-')
      let toArr = to.target.value.split('-')
      const existingFilters = { ...selectedFilters };
      existingFilters['toDate'] = to.target.value;
      existingFilters['fromDate'] = differenceInCalendarDays(new Date(toArr[0], toArr[1], toArr[2]), new Date(fromArr[0], fromArr[1], fromArr[2]))
      setSelectedFilters(existingFilters);
    }
  }

  const handleFilterClear = (filterName, filterValue) => {
    handleFilterClearUtil(
      filterName,
      filterValue,
      selectedFilters,
      setSelectedFilters,
    );
  };

  const handleExport = async () => {
    return await safe7Question(questionParm.replace('-','_'), selectedFilters, true);
  };

  const getData = async (selectedFilters) => {

    const stats = await safe7Question(questionParm.replace('-','_'), selectedFilters);
    setServerData(stats);
    setIsLoading(false);
    setFiltersLoading(false)
  };

  if (isLoading) {
    return <Loader />;
  }

  const {
    age_breakdown,
    completed_by,
    daily_average,
    gender_breakdown,
    overall_scores_breakdown,
    question_scores,
  } = serverData;

  return (
    <Safe7Questionjsx
      handleFilterChange={handleFilterChange}
      handleFilterClear={handleFilterClear}
      handleExport={handleExport}
      selectedFilters={selectedFilters}
      ageBreakdown={age_breakdown}
      completedBy={completed_by}
      dailyAverage={daily_average}
      genderBreakdown={gender_breakdown}
      overallScoresBreakdown={overall_scores_breakdown}
      questionScores={question_scores}
      questionHeader={questionHeader}
      isLoading={filtersLoading}
      customDateActive={customDateActive}
      handleCustomFromDate={handleCustomFromDate}
      handleCustomToDate={handleCustomToDate}
    />
  );
};

export default Safe7Question;
