import './App.sass';

import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Header from './components/header/Header';

import Dashboard from './pages/dashboard/Dashboard';
import Safe7 from './pages/safe7/Safe7';
import Safe7Question from './pages/Safe7Question/Safe7Question';
import Stats from './pages/stats/Stats'
import Profile from './pages/profile/Profile';
import Invite from './pages/invite/Invite'

import Login from './pages/auth/login/Login';
import ForgotPassword from './pages/auth/forgot-password/ForgotPassword';
import ResetPassword from './pages/auth/reset-password/ResetPassword';
import Verify from './pages/auth/verify/Verify';

import { createBrowserHistory } from 'history';
import Export from './pages/export/Export.js';


const history = createBrowserHistory();

function App() {
  const [isDashboard, setIsDashboard] = React.useState(false)

  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  const routerChange = () => {
    if(window.location.pathname.includes('/dashboard')) {
      setIsDashboard(true);
      console.log(isDashboard)
    }
    console.log(window.location.pathname);
  }

  return (
    <Router>
      <div className='App'>
        <Switch>
          <Route exact path={['/', '/login']}>
            <Login />
          </Route>
          <Route path='/forgot-password'>
            <ForgotPassword />
          </Route>
          <Route path='/reset-password'>
            <ResetPassword />
          </Route>
          <Route path='/verify'>
            <Verify />
          </Route>
          <Route
            path='/dashboard'
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`}>
                  <Header active={'dashboard'} />
                </Route>
                <Route path={`${url}/`} exact onChange={routerChange}>
                  <Dashboard />
                </Route>
              </>
            )}
          />
          <Route
            path='/stats'
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`}>
                  <Header active={'stats'} />
                </Route>
                <Route path={`${url}/`} exact onChange={routerChange}>
                  <Stats />
                </Route>
              </>
            )}
          />
          <Route
            path='/safe7/:questionParm'
            exact
            render={({ match: { url } }) => (
              <>
                  <Header active={'safe7'} />
                  <Safe7Question />
              </>
            )}
          />
          <Route
            path='/safe7/'
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`}>
                  <Header active={'safe7'} />
                </Route>
                <Route path={`${url}/`} exact onChange={routerChange}>
                  <Safe7 />
                </Route>
              </>
            )}
          />
          <Route
            path='/invite'
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`}>
                  <Header active={'invite'} />
                </Route>
                <Route path={`${url}/`} exact onChange={routerChange}>
                  <Invite />
                </Route>
              </>
            )}
          />
          <Route
            path='/export'
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`}>
                  <Header active={'export'} />
                </Route>
                <Route path={`${url}/`} exact onChange={routerChange}>
              <Export />
                </Route>
              </>
            )}
          />
          <Route
            path='/profile'
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`}>
                  <Header active={'profile'} />
                </Route>
                <Route path={`${url}/`} exact onChange={routerChange}>
                  <Profile />
                </Route>
              </>
            )}
          />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
