import { Box } from '@material-ui/core';
import './Block.sass';

const BlockWrapper = ({ children }) => {
  return <div className='block-wrapper'>
    <Box mx={3.157} my={3.157}>
      {children}
    </Box>
  </div>
}

export default BlockWrapper;