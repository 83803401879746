import React from 'react';
import { Grid, Box, Typography, Link } from '@material-ui/core';
import { PieChartBlock } from '../../charts/PieChartBlock';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { getBarometerFill } from '../../../util/filter-utils';
import './BarometerBlock.sass';

const getFill = (score) => {
  if (score > 7) {
    return '#7CB45D'
  } else if (score > 5) {
    return '#F2B111'
  } else {
    return '#DA4832'
  }
}

const getBarometerData = (score) => {
  return [
    {
      name: 'Non Score',
      value: 10 - score,
      fill: '#eee',
    },
    {
      name: 'Score',
      value: score,
      fill: getBarometerFill(score),
    },
  ];
}

export const BarometerBlock = ({ isFullWidth, copy, score, link }) => {
  // TODO: Remove temp previous test value
  const previousTest = 5;
  const change = score ? score.current - previousTest : undefined;
  return (
    <React.Fragment>
      <Grid
        container
        className={`${isFullWidth ? 'full-width-barometer-bg' : ''}`}
      >
        {isFullWidth && (
          <Grid item xs={6} className={'barometer-container'}>
            <Box mx={4}>
              <Typography variant={'h4'}>
                {copy
                  ? copy
                  : 'Lorem ipsum dolor elit episcing crispy waffle pancakes'}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={isFullWidth ? 6 : 12}>
          {!isFullWidth && (
            <Box mt={4} mb={-8} mx={4}>
              <Typography variant={'subtitle1'}>
                <strong>
                  {copy
                    ? copy
                    : 'Lorem ipsum dolor elit episcing crispy waffle pancakes'}
                </strong>
              </Typography>
            </Box>
          )}
          <Grid container>
            <Grid item xs={4}>
              <PieChartBlock
                pieData={getBarometerData(score ? score.current : undefined)}
                pieInnerRadius={isFullWidth ? 30 : 20}
                pieOuterRadius={isFullWidth ? 55 : 40}
              />
            </Grid>
            <Grid item xs={8} className={'barometer-container'}>
              <Typography variant={'h4'}>
                {score ? score.current : `--`}/10
              </Typography>
              <Typography className={'change-row'}>
                {change > 0 && (
                  <ArrowUpwardIcon
                    style={{
                      color: getBarometerFill(
                        score ? score.current : undefined,
                      ),
                    }}
                  />
                )}
                {change < 0 && (
                  <ArrowDownwardIcon
                    style={{
                      color: getBarometerFill(
                        score ? score.current : undefined,
                      ),
                    }}
                  />
                )}
                {change && (
                  <div className={'change-text'}>
                    {change.toFixed(2)} change since previous
                  </div>
                )}
              </Typography>
              {link && <Link href={link} className={'view-more-link'}>View More</Link>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
