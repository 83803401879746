import axios from 'axios';
import format from 'date-fns/format';
import QueryString from 'query-string';
import subDays from 'date-fns/subDays';

const dateFormat = 'yyyy-MM-dd';

const getToDate = (date) => {
  if(date) {
    return date;
  } else {
  const now = new Date();
  const toDate = format(now, dateFormat);
  return toDate;
  }
}
const getFromDate = (date) => {
  if(date === undefined) {
    let now = new Date();
    const prev = subDays(now, 30);
    let fromDate = format(prev, dateFormat);
    // alert(fromDate)
    return fromDate;

  } else {
    let now = new Date();
    const prev = subDays(now, date);
    let fromDate = format(prev, dateFormat);
    // alert(fromDate)
    return fromDate;
  }
}

const processArrayParam = (arrayParam) => arrayParam && arrayParam.length ? arrayParam.map((item) => item.value) : undefined;

const getConfig = (params) => {
  const key = localStorage.getItem('jwt');
  let timestamp = localStorage.getItem('timestamp');
  if (!key || !timestamp) {
    localStorage.clear();
    window.location.href = '/login';
    return;
  }
  let currentTime = Date.now();
  let timeLimit = parseInt(timestamp) + 12 * 60 * 60 * 1000;
  if (timeLimit <= currentTime) {
    localStorage.clear();
    window.location.href = '/login';
    return;
  }

  const config = {
    headers: {
      Authorization: `Bearer ${key}`,
    },
  };

  if (params) {
    console.log(params)
    config.params = {
      completed_by: !!params.completed_by && params.completed_by !== 'all' ? [params.completed_by] : [],
      age: processArrayParam(params.age),
      gender: processArrayParam(params.gender),
      to: params.toDate ? getToDate(params.toDate) : getToDate(),
      from: params.fromDate ? getFromDate(params.fromDate) : getFromDate(params.date),
      travel: processArrayParam(params.transport),
      gender_identity: processArrayParam(params.genderIdentity),
      service_type: processArrayParam(params.serviceType),
      sexual_orientation: processArrayParam(params.orientation),
      liveAlone: processArrayParam(params.liveAlone),
      ethnicity: processArrayParam(params.ethnicity),
    };
    config.paramsSerializer = (params) => {
      return QueryString.stringify(params);
    };
    if (params.download) {
      config.params['download'] = true;
    }
  }

  return config;
};

const getRequest = async (endpoint, params, arrayBuffer) => {
  const config = getConfig(params);
  console.log(config)
  const request = await axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/${endpoint}`,
    arrayBuffer ? {...config, responseType: 'arraybuffer'} : config
  );
  return request.data;
};

export const dashboardOverview = async (params, isExport = false) => {
  return getRequest(`dashboard/overview`, { ...params, download: isExport });
};

export const generalStats = async (params, isExport = false) => {
  console.log(params)
  return getRequest(`dashboard/general-stats`, { ...params, download: isExport });
};

export const serviceExport = async (params, isExport = false) => {
  return getRequest(`dashboard/free-text-responses`, { ...params, download: isExport }, true);
};

export const safe7 = async (params, isExport = false) => {
  return getRequest(`dashboard/safe-7/overview`, { ...params, download: isExport });
};

export const safe7Question = async (question, params, isExport = false) => {
  return getRequest(`dashboard/safe-7/${question}`, { ...params, download: isExport });
};

export const profile = async () => {
  return getRequest(`profile`);
}

const postRequest = async (endPoint, postData) => {
  const config = getConfig();
  return axios.post(
    `${process.env.REACT_APP_API_ENDPOINT}/${endPoint}`,
    postData,
    config,
  );
}
export const createSurveyInvitation = async (postData) => {
  return postRequest(`survey/create-invitation`, postData);
}

export const resetPassword = async (postData) => {
  return postRequest(`change-password`, postData);
}
