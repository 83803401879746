import React from 'react';
import {
  Box,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Checkbox,
  TextField,
  IconButton,
  Button
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MainContentWrapper from '../../components/wrappers/MainContent/MainContent';
import Heading from '../../components/heading/Heading';
import './Export.sass'
import '../../components/blocks/filter/FilterBlock.sass'
import { dateFilter, serviceTypeFilter } from '../../components/blocks/filter/filterOptions';


import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export const ExportJSX = ({
  handleExportSubmission,
  errors,
  handleFilterChange,
  selectedFilters,
  handleCustomFromDate,
  handleCustomToDate,
  handleDateSelectChange,
  date,
  handleFilterClear,
}) => {
  const multiSelects = [{
    name: 'service_type',
    label: 'Clinic Area',
    options: serviceTypeFilter,
    values: selectedFilters,
  }]
  return (
    <MainContentWrapper>
      <Box mt={3.157} mb={1}>
        <Heading >Word Cloud Export</Heading>
      </Box>
      <Box className='block-wrapper' >
        <Box style={{ padding: '25px', paddingBottom: 0, display: 'flex', alignItems: 'center' }}>
          {multiSelects.map((item) => {
            return (
              <div className={'select'} style={{ marginTop: 0, }}>
                <InputLabel className='label'>{item.label}</InputLabel>
                <Autocomplete
                  multiple
                  id={item.name}
                  name={item.name}
                  options={item.options}
                  onChange={(event, newValue) => {
                    handleFilterChange(
                      newValue,
                      item.name,
                    );
                  }}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.label}
                  renderTags={() => null}
                  renderOption={(option) => {
                    const selectedValues = item.values
                    const isSelected = selectedValues.some((sv) => sv.value === option.value);
                    return (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={isSelected}
                        />
                        {option.label}
                      </React.Fragment>
                    );
                  }}
                  renderInput={(params) => (
                    <div style={{ position: 'relative' }} ref={params.InputProps.ref}>
                      <input
                        style={{ minWidth: 220 }}
                        {...params.inputProps}
                        className={'filter-select-input'}
                        placeholder={item.values.length ? item.values.map((value) => value.label) : 'All'}
                      />
                      <IconButton className={'down-icon-absolute'} onClick={() => { console.log('icon focus') }}>
                        <ArrowDropDownIcon />
                      </IconButton>
                    </div>
                  )}
                />
              </div>)
          })}
          <Grid item >
            <div style={{ marginTop: 0, marginLeft: 16 }} className={'select'}>
              <InputLabel className='label'>{'Date'}</InputLabel>
              <Select
                name={'date'}
                variant={'outlined'}
                className={'filter-select'}
                value={date}
                onChange={handleDateSelectChange}
              >
                {dateFilter.map((option) => (
                  <MenuItem key={option.label} className='item' value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
          <Grid item >
            {
              date === 'custom' && <Box display={'flex'} justifyContent={'center'}>
                <Box mr={2}>
                  <InputLabel className='label'>{'From:'}</InputLabel>
                  <TextField onChange={handleCustomFromDate} type={'date'} variant={'outlined'}></TextField>
                </Box>
                <Box>
                  <InputLabel className='label'>{'To:'}</InputLabel>
                  <TextField onChange={handleCustomToDate} type={'date'} variant={'outlined'}></TextField>
                </Box>
              </Box>
            }
          </Grid>
        </Box>
        {errors && <Box style={{ padding: '0px 25px', paddingBottom: 0 }}>
          <Typography color='error' >{errors}</Typography>
        </Box>}
        {selectedFilters && selectedFilters.length > 0 ?
          <Box style={{ padding: '0px 25px' }}>
            <Grid container className={'selected-filter-row'}>{
              selectedFilters.map((filter, index) =>
                <div className={'active-filter'} key={filter.value}>
                  {filter.label}
                  <div className={'separator'} />
                  <IconButton
                    className={'close-icon'}
                    onClick={() => handleFilterClear(filter.value)}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              )
            }</Grid> </Box> : ''}
        <Box style={{ padding: '0px 25px', paddingBottom: 25 }} >
          <Grid item xs={12} md={3}>
            <Box mx={1} my={3.157}>
              <Button variant="contained" onClick={handleExportSubmission} align="center" className='btn-theme' type='button'><strong>Export</strong></Button>
            </Box>
          </Grid>
        </Box>
      </Box>
    </MainContentWrapper >
  )
}

export default ExportJSX
