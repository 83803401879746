import React from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, CartesianGrid } from 'recharts';

export const LineChartBlock = ({ barData, height, unit, yDomain }) => {

  const data01 = [
    {
      "name": "April 1",
      "value": 6.4
    },
    {
      "name": "April 6",
      "value": 7.1,
    },
    {
      "name": "April 11",
      "value": 4.5,
    },
    {
      "name": "April 16",
      "value": 6.2,
    },
    {
      "name": "April 21",
      "value": 7.8
    },
    {
      "name": "April 26",
      "value": 7.2
    },
    {
      "name": "April 30",
      "value": 6.8
    }
  ];


  return (
    <ResponsiveContainer width={'95%'} height={height ? height : 238}>
      <LineChart data={barData || data01}>
        <CartesianGrid />
        <XAxis dataKey={'name'} />
        <YAxis
          tickCount={11}
          domain={yDomain || [(0, 'dataMax')]}
          unit={unit}
          width={unit ? 80 : 40}
        />
        <Line
          type={'monotone'}
          dataKey='value'
          strokeWidth={3}
          stroke='#82ca9d'
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
