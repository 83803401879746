import React from 'react';
import { Box, Typography, Modal, Grid } from '@material-ui/core';
import MainContentWrapper from '../../components/wrappers/MainContent/MainContent';
import BlockWrapper from '../../components/wrappers/Block/Block';
import {FilterBlock} from '../../components/blocks/filter/FilterBlock';

import {BarChartBlock} from '../../components/charts/BarChartBlock';
import BarometerBlockContainer from '../../components/blocks/barometer/BarometerBlockContainer';
import EngagementScoreBlock from '../../components/blocks/EngagementScoreBlock';

import Heading from '../../components/heading/Heading';
import GenderBlock from '../../components/blocks/GenderBlock';


export const DashboardJsx = (
  {
    open,
    handleClose,
    handleExport,
    ageBreakdown,
    barometers,
    completedBy,
    dailyAverage,
    deprivationBreakdown,
    genderBreakdown,
    handleFilterChange,
    selectedFilters,
    surveyInvitations,
    wordCloud,
    isLoading,
    customDateActive,
    handleCustomFromDate,
    handleCustomToDate
  }
) => {

  console.log(surveyInvitations)

  const overallEngagementScore = [
    {
      "name": "Responder",
      "value": surveyInvitations.responder ? surveyInvitations.responder.count : 0 ,
      "fill": "#7CB45D"
    },
    {
      "name": "Non-Responder",
      "value": surveyInvitations.non_responder ? surveyInvitations.non_responder.count : 0 ,
      "fill": "#4B6195"
    },
    {
      "name": "Declined",
      "value": surveyInvitations.declined ? surveyInvitations.declined.count : 0 ,
      "fill": "#FFEB3B"
    }
  ];

return (
  <MainContentWrapper isLoading={isLoading}>
    <Box mt={3.157} mb={1}>
      <Heading showExport handleExport={handleExport}>Dashboard</Heading>
    </Box>
    <BlockWrapper>
      <FilterBlock
        showParticipants
        showDateRange
        showCustomDate={customDateActive}
        handleCustomFromDate={handleCustomFromDate}
        handleCustomToDate={handleCustomToDate}
        handleFilterChange={handleFilterChange}
        selectedFilters={selectedFilters}
      />
    </BlockWrapper>
    {barometers.safe_care &&
    <>
    <EngagementScoreBlock
      overallEngagementScore={overallEngagementScore}
      lineChartData={dailyAverage.map((dailyAv) => { return { name: dailyAv.date, value: dailyAv.average } })}
      completedBy={completedBy}
      barometers={barometers}
      surveyInvitations={surveyInvitations}
      averageScore={barometers.safe_care ? barometers.safe_care.current : '--'}
    />
    <BarometerBlockContainer
      title={'Safe-7 Barometers'}
      barometers={barometers}
      link
      fullWidthAtTop
    />
    <GenderBlock
      ageBreakdown={ageBreakdown}
      genderBreakdown={genderBreakdown}
      showLink
      singleBlockTitle={'General Stats'}
    />
    </>
    }
    {!barometers.safe_care &&
      <BlockWrapper>
      <Box my={12}>
      <Typography variant={'h5'} align={"center"} className={'grey-font'}>There is no data to display for this filter</Typography>
      </Box>
      </BlockWrapper>
    }
     {!!deprivationBreakdown && <BlockWrapper>
          <Grid container>
            <Grid item xs={6}>
              <Box mb={3.109}>
                <Typography variant={'h5'}>Deprivation</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <BarChartBlock
              barData={deprivationBreakdown.map((deprivation) =>  ({ name:  deprivation.score, value: deprivation.count }))}
              YAxisMaxDomain={100}
            />
          </Grid>
        </BlockWrapper>
        }
    <Modal
    open={open}
    onClose={() => handleClose()}
    >
    </Modal>
</MainContentWrapper>
)
}

export default DashboardJsx;
