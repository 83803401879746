import React from 'react';
import { Box, Typography, Grid, Link } from '@material-ui/core';
import BlockWrapper from '../wrappers/Block/Block';
import { PieChartBlock } from '../charts/PieChartBlock';
import { BarChartBlock } from '../charts/BarChartBlock';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const GenderBlock = ({
  ageBreakdown,
  genderBreakdown,
  showLink,
  singleBlockTitle,
  ageTitle,
  ageRange,
}) => (
  <BlockWrapper>
    <Grid container>
      <Grid item xs={6}>
        <Box my={1.777}>
          <Typography variant={'h5'}>
            {singleBlockTitle ? singleBlockTitle : 'Gender'}
          </Typography>
        </Box>
      </Grid>
      {ageTitle && (
        <Grid item xs={6}>
          <Box my={1.777}>
            <Typography variant={'h5'}>{ageTitle}</Typography>
          </Box>
        </Grid>
      )}
      {showLink && (
        <Grid item xs={6}>
          <Link href={'/stats'}>
            <Typography variant={'subtitle1'} align={'right'}>
              View more
            </Typography>
          </Link>
        </Grid>
      )}
    </Grid>
    <Grid container>
      <Grid item xs={6} className={'theme-box'}>
        <Grid container>
          {genderBreakdown.male && genderBreakdown.female && (
            <>
              <Grid item xs={6}>
                <PieChartBlock
                  pieData={[
                    {
                      name: 'Male',
                      value: genderBreakdown.male.percentage,
                      fill: '#7CB45D',
                    },
                    {
                      name: 'Female',
                      value: genderBreakdown.female.percentage,
                      fill: '#4B6195',
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={6} className={'barometer-container'}>
                <Typography variant={'h5'}>
                  <FiberManualRecordIcon
                    fontSize={'small'}
                    style={{ color: '#7CB45D' }}
                  />
                  Male {genderBreakdown.male.percentage}% ({genderBreakdown.male.count})
                </Typography>
                <Typography variant={'h5'}>
                  <FiberManualRecordIcon
                    fontSize={'small'}
                    style={{ color: '#4B6195' }}
                  />{' '}
                  Female {genderBreakdown.female.percentage}% ({genderBreakdown.female.count})
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {!ageRange && (
        <Grid item xs={6} className={'theme-box barometer-container'}>
          <BarChartBlock barData={ageBreakdown.map((age) => { return {name: age.choice, value: age.count}})} />
        </Grid>
      )}
      {ageRange && (
        <Grid item xs={6} className={'theme-box barometer-container'}>
          <BarChartBlock barData={ageBreakdown.map((age) => { return { name: age.choice, value: age.count}})}/>
        </Grid>
      )}
    </Grid>
  </BlockWrapper>
);

export default GenderBlock;
